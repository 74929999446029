import axios from 'axios'

/* eslint-disable no-empty-pattern */
export default ({}, inject) => {
  inject('geocode', async (address) => {
    if (address !== '') {
      // ハイフンを全角に変換
      const normalizedAddress = address.replace(/-/g, '−');

      const url = 'https://maps.googleapis.com/maps/api/geocode/json'
      const key = process.env.GOOGLE_MAPS_API_KEY

      try {
        const res = await axios.get(url, {
          params: {
            address: normalizedAddress,
            key,
          },
        })
        // console.log(res)
        const location = res.data.results[0].geometry.location
        // console.log(location)
        return location
      } catch (error) {
        console.error("couldn't find location.")
      }
    }
  })
}
